import styled from 'styled-components'
import Colors from '../../utils/Colors'

const Link = styled.a`
  color: ${(props) => (props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary)};
  text-decoration: none;
  &:hover {
    border-bottom: 2px solid;
    border-color: ${(props) => (props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary)};
  }
`

export default Link
