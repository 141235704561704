// import styled from 'styled-components';
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { myContext } from 'src/themeProvider'

import { HireMe } from 'components/Button'
import ContentWrapper from 'components/Content'
import Layout from 'components/Layout'
import Link from 'components/link'

import * as t from 'utils/Typography'
import { AboveFold, Background } from 'utils/styles'

function RenderPage(props) {
  const { theme, changeTheme, data } = props

  return (
    <Background
      theme={theme}
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        girdTemplateRows: '1fr minmax(280px, 490px)',
      }}>
      <Layout theme={theme} onChangeTheme={changeTheme}>
        <AboveFold theme={theme}>
          <ContentWrapper theme={theme} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <GatsbyImage image={getImage(data.header)} alt="Bikini Doodle" />
            <t.H1 primary align="left">
              Cross-Platform frameworks: A comparative study
            </t.H1>
            <t.H3 secondary align="left">
              An objective comparison between the most commonly used cross-platform frameworks.
            </t.H3>
            <t.P align="left">
              Developed as a collaboration project between&nbsp;
              <Link theme={theme} href="https://intelygenz.com/" target="_blank" rel="noreferrer">
                Intelygenz Software
              </Link>
              &nbsp;and the&nbsp;
              <Link theme={theme} href="https://www.uca.es/?lang=en/" target="_blank" rel="noreferrer">
                University of Cádiz
              </Link>
              .
            </t.P>
            <t.P align="left">
              The study aimed to dispel doubts when choosing a cross-platform development. For this, we compared the
              most used cross-platform frameworks in the software development industry (React Native, Ionic, Flutter and
              Weex) have been compared in terms of execution times and code quality, offering an objective comparison
              between them.
            </t.P>
            <GatsbyImage
              style={{ float: 'right', minWidth: '300px', maxWidth: '300px', margin: '30px auto' }}
              image={getImage(data.flutter_app_screenshot)}
              alt="Flutter Benchmark app"
            />
            <t.P align="left">
              This comparison has been made through the development of four benchmark applications, one in each of the
              frameworks and the measurement of metrics in them for the subsequent contrast of the data obtained.
            </t.P>
            <t.P align="left">
              For each of the developed applications, we measured various metrics, such as execution times, the number
              of objects created, and the number of function calls. These measurements were taken while performing
              simple CRUD tasks (add, remove, update, and delete) on lists of varying lengths (100, 500, and 1000
              elements each).
            </t.P>
            <t.P align="left">
              With the help of SonarQube, we also gathered code quality metrics, such as code smells and the total
              number of lines in the project.
            </t.P>
            <t.P align="left">
              We concluded that, despite the difference in performance compared to native applications, cross-platform
              development is an alternative to consider when undertaking mobile development due to its significant
              advantages in terms of versatility and cost.
            </t.P>
            <HireMe
              large
              onClick={() => window.open('https://rodin.uca.es/handle/10498/20951', '_blank')}
              book
              theme={theme}
              style={{ marginTop: '30px' }}>
              Check it out!&nbsp;
              <span role="img" aria-label="zap">
                ⚡️
              </span>
            </HireMe>
          </ContentWrapper>
        </AboveFold>
      </Layout>
    </Background>
  )
}

function CrossPlatformStudyPage(props) {
  const { data } = props
  return (
    <myContext.Consumer>
      {(context) => <RenderPage theme={context.theme} changeTheme={context.changeTheme} data={data} />}
    </myContext.Consumer>
  )
}

export default CrossPlatformStudyPage

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "cross_study_header.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: BLURRED)
      }
    }
    flutter_app_screenshot: file(relativePath: { eq: "cross_study_screenshot.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: BLURRED)
      }
    }
  }
`
